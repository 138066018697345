<template>
  <div class="form-group">
    <ValidationProvider :rules="rules" :bails="false" v-slot="{ errors }">
      <div translate="" class="control-label bold small text-uppercase">
        <slot></slot>
      </div>

      <div v-if="type === 'select'" style="position: relative">
        <input
          v-if="isdrop"
          :disabled="disabled"
          type="text"
          v-model="filtro"
          @focusin="dropdown($event)"
          @blur="dropclose($event)"
          class="form-control form-control-rounded"
          :placeholder="placeholder"
        />
        <input
          v-else
          type="text"
          :disabled="disabled"
          :value="dropselected"
          @focusin="dropdown($event)"
          class="form-control form-control-rounded"
          :placeholder="placeholder"
        />

        <div class="dropdown" :style="dropstyle">
          <ul>
            <li v-for="(o, ok) in optionsFiltered" :key="'opt-' + ok">
              <a @click="dropselect(o)">{{ o[info.name] }}</a>
            </li>
            <li v-if="optionsFiltered.length == 0">
              <a @click="dropselect({ id: 0, name: 'SELECIONE' })"
                >- Não sobrou nenhuma opção</a
              >
            </li>
          </ul>
        </div>
      </div>
      <template v-else-if="type === 'textarea'">
        <textarea
          :value="value"
          :disabled="disabled"
          @input="$emit('input', $event.target.value)"
          class="form-control form-control-rounded"
          style="height: 100px"
          :placeholder="placeholder"
        ></textarea>
        <div
          class="alert alert-danger"
          style="margin-top: 5px"
          v-if="errors.length > 0"
        >
          {{ errors[0] }}
        </div>
      </template>
      <template v-else-if="type === 'radio'">
        <template v-for="(o, ok) in options">
          <div
            :key="'opt-' + ok"
            class="radio"
            style="padding: 20px; padding-bottom: 0px; float: left"
            @click="dropselect(o)"
          >
            <div>
              <input
                :checked="value == o[info.key]"
                style="
                  width: 16px;
                  height: 16px;
                  margin: 2px;
                  margin-right: 15px;
                  float: left;
                "
                type="radio"
              />
              {{ o[info.name] }}
            </div>
          </div>
          <div :key="'opts-' + ok" v-if="info.inline" style="clear: both"></div>
        </template>
        <div style="clear: both"></div>
      </template>
      <template v-else-if="type === 'check'">
        <template v-for="(o, ok) in options">
          <div
            :key="'opt-' + ok"
            class="radio"
            style="padding: 20px; padding-bottom: 0px; float: left"
            @click="dropselect(o)"
          >
            <div>
              <input
                :checked="value == o[info.key]"
                style="
                  width: 16px;
                  height: 16px;
                  margin: 2px;
                  margin-right: 15px;
                  float: left;
                "
                type="checkbox"
              />
              {{ o[info.name] }}
            </div>
          </div>

          <div :key="'opts-' + ok" v-if="info.inline" style="clear: both"></div>
        </template>
        <div style="clear: both"></div>
      </template>
      <template v-else>
        <input
          v-if="kmask"
          :disabled="disabled"
          :type="type"
          :value="value"
          v-mask="kmask"
          @input="$emit('input', $event.target.value)"
          class="form-control form-control-rounded"
          :placeholder="placeholder"
        />
        <input
          v-else
          :type="showPassword ? 'text' : type"
          :disabled="disabled"
          :value="value"
          @input="$emit('input', $event.target.value)"
          class="form-control form-control-rounded"
          :placeholder="placeholder"
        />
        <v-icon
          v-if="type === 'password'"
          class="password-icon"
          tabindex="-1"
          @click="showPassword = !showPassword"
        >
          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>

        <div
          class="alert alert-danger"
          style="margin-top: 5px"
          v-if="errors.length > 0"
        >
          {{ errors[0] }}
        </div>
      </template>
    </ValidationProvider>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  position: absolute;
  left: 0px;
  top: 35px;
  background: white;
  z-index: 2000;
  max-height: 500px;
  overflow: auto;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  border: #becde4 1px solid;
  border-radius: 5px;
  outline: 1px transparent solid;

  ul {
    list-style: none !important;
    margin: 0px;
    padding: 0px;

    li {
      text-align: left;
      position: relative;
      a {
        text-align: left;
        color: #0f131e;
        text-decoration: none;
        font-size: 13px;
        padding: 10px;
        display: block;
        user-select: none;
        cursor: pointer;

        .icon {
          float: left;
          width: 15px;
          text-align: center;
          line-height: 20px;
        }

        .label {
          margin-left: 28px;
          margin-right: 80px;
          padding-right: 20px;
          line-height: 20px;
        }

        .info {
          float: right;
          color: #0b1b36;
          font-size: 11px;
          line-height: 20px;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);

        .submenu {
          display: block;
        }
      }

      .separator {
        border-bottom: #00a0e0 1px dotted;
        height: 1px;
        width: 100%;
      }

      .submenu {
        display: none;
        position: absolute;
        background: white !important;
        z-index: 2010;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
        border: #becde4 1px solid;
        border-radius: 5px;
        outline: 1px transparent solid;
        left: 290px;
        top: 0px;
        width: 300px;

        ul {
          li {
            a {
              .label {
                margin-left: 28px !important;
                padding-right: 20px !important;
                line-height: 20px !important;
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

.form-group {
  position: relative;
}

.password-icon {
  position: absolute !important;
  top: 22px;
  right: 12px;
  z-index: 1;
}
</style>

<script>
import _ from "lodash";

export default {
  props: {
    kmask: {
      default: false,
    },
    value: {},
    rules: {
      default: "",
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      default: false,
    },
    type: {
      default: "text",
    },
    disabled: {
      default: false,
    },
    options: {
      default: false,
    },
    info: {
      default: false,
    },
  },
  data: function () {
    return {
      filtro: "",
      isdrop: false,
      dropx: 0,
      dropy: 0,
      dropw: 0,
      showPassword: false,
    };
  },
  computed: {
    dropstyle: function () {
      return { display: this.isdrop ? "block" : "none", width: "100%" };
    },
    dropselected: function () {
      var s = _.find(this.options, (e) => {
        if (e[this.info.key] === this.value) {
          return true;
        }
      });

      return s ? s[this.info.name] : "SELECIONE";
    },

    optionsFiltered: function () {
      return _.filter(this.options, (i) => {
        var q = this.filtro.toLowerCase();
        if (i[this.info.name].toLowerCase().match(q)) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    dropselect: function (i) {
      this.filtro = i[this.info.name];
      this.$emit("input", i[this.info.key]);
      this.$emit("changed", i[this.info.key]);
    },
    // eslint-disable-next-line no-unused-vars
    dropdown: function (e) {
      this.isdrop = true;
      this.filtro = "";
    },
    // eslint-disable-next-line no-unused-vars
    dropclose: function (e) {
      window.setTimeout(() => {
        this.isdrop = false;
      }, 600);
    },
  },
};
</script>